import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import ModifyEmployeeInvestigation from "../../../../../components/incidents/forms/modiy/ModifyEmployeeInvestigation";
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from "hugeicons-react";
import BackToPage from "../../../../../components/incidents/backToPage";
import { ArrowRight01Icon } from "hugeicons-react";
import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";
import NoResources from "../../../../../components/general/noResources";
const ModifyEmployeeInvestigationPageContent = () => {
  const [error, setError] = useState();
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    console.log(incidentId);

    const fetchIncidentData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(
          `/incidents/employee_health_investigation/${incidentId}/`
        );
        if (response.status === 200) {
          setIncident(response.data.employee_investigation);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          setIsError(true);
        }
        setError("Error getting incident data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncidentData();
  }, [incidentId]);

  return isLoading ? (
    "Getting data..."
  ) : incident && !isError ? (
    <div>
      <ModifyEmployeeInvestigation data={incident} incidentId={incidentId} />
    </div>
  ) : (
    <NoResources />
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/investigation/"}>
        Grievance Investigation List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/general/${incidentId}/`}>#{incidentId}</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyEmployeeInvestigationPage = () => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<ModifyEmployeeInvestigationPageContent />}
      breadCrumbs={
        changeBreadCrumbs ? <FacilityBreadCrumbs /> : <BreadCrumbs />
      }
    />
  );
};

export default ModifyEmployeeInvestigationPage;
