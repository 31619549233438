import React, { useEffect, useState } from "react";
import DashBoardContainer from "./dashboardContainer";
import { Link, useParams } from "react-router-dom";
import {
  ArrowRight01Icon,
  Delete01Icon,
  GoogleDocIcon,
  LayersLogoIcon,
  ListViewIcon,
  Loading03Icon,
  PencilEdit02Icon,
  Sad01Icon,
  UserBlock01Icon,
  UserCheck01Icon,
} from "hugeicons-react";
import api, { calculateAge } from "../../api";
import "../../assets/css/pages/profile/profile.css";
import ProfileReports from "../../components/profile/profileReports";
import UserComplaints from "../../components/profile/profileComplaints";
import ProfileDocuments from "../../components/profile/profileDocuments";
import { DraftsTab } from "./incidentTrackingPage";
import UpdateUserProfileForm from "../../components/incidents/forms/admin/updateUserProfileForm";
import { usePermission } from "../../contexts/permissionsContext";
import NoResources from "../../components/general/noResources";

const ProfilePageContent = () => {
  const { userId } = useParams();
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);
  const [age, setAge] = useState(null);
  const [activating, setActivating] = useState(false);
  const hasSuperUserPermission = useCheckPermissions([
    "Super User",
    "User Editor",
    "Admin",
  ]);

  const toggleDepartmentForm = () => {
    setShowUpdateUserForm(!showUpdateUserForm);
  };
  useEffect(() => {
    const fetchProfile = async () => {
      setError("");
      try {
        const response = await api.get(`accounts/profile/${userId}/`);
        setProfile(response.data);
        setIsLoading(false);
        if (response.data.profile?.date_of_birth) {
          setAge(calculateAge(response.data.profile?.date_of_birth));
        }
      } catch (error) {
        if (error.response) {
          setError(
            error.response.data.message ||
              error.response.data.error ||
              "Error fetching profile data"
          );
          setIsError(true);
        }
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  const handleDeactivate = async () => {
    setActivating(true);
    try {
      const response = await api.put(`accounts/users/${userId}/deactivate/`);
      if (response.status === 200) {
        alert("User deactivated successfully");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        alert(
          error.response.data.message ||
            error.response.data.error ||
            "Error deactivating user"
        );
      } else {
        alert("An error occurred while deactivating user");
      }
      console.log(error);
    } finally {
      setActivating(false);
    }
  };
  const handleActivate = async () => {
    setActivating(true);
    try {
      const response = await api.put(`accounts/users/${userId}/reactivate/`);
      if (response.status === 200) {
        alert("User reactivated successfully");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        alert(
          error.response.data.message ||
            error.response.data.error ||
            "Error deactivating user"
        );
      } else {
        alert("An error occurred while deactivating user");
      }
      console.log(error);
    } finally {
      setActivating(false);
    }
  };
  return isLoading ? (
    "..."
  ) : !isError ? (
    <div className="dashboard-page-content profile-page">
      {showUpdateUserForm ? (
        <div className="new-user-form-popup">
          <div className="popup-content">
            <UpdateUserProfileForm
              toggleDepartmentForm={toggleDepartmentForm}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {error && <div className="error-message">{error}</div>}
      <div className="profile-information">
        <div className="basic-info">
          <div className="profile-info">
            <img
              src={
                profile?.profile_url
                  ? profile?.profile_url
                  : "/images/branding/logo.png"
              }
              alt=""
              className="profile-image"
            />
            <h3 className="full-name">
              {profile?.last_name} {profile?.first_name}
            </h3>
            <small className="email">{profile?.email}</small>
            <div className="positions">
              {profile?.permissions?.length > 3 ? (
                <>
                  {profile?.permissions
                    ?.slice(0, 3)
                    .map((permission, index) => (
                      <div key={index} className="position">
                        <div className="dot"></div>
                        <small>{permission}</small>
                      </div>
                    ))}
                </>
              ) : (
                profile?.permissions?.slice(0, 3).map((permission, index) => (
                  <div key={index} className="position">
                    <div className="dot"></div>
                    <small>{permission}</small>
                  </div>
                ))
              )}
            </div>
            {hasSuperUserPermission && (
              <div className="profile-actions">
                <Link
                  className="button primary-button"
                  onClick={toggleDepartmentForm}
                >
                  <PencilEdit02Icon size={20} />
                  <span>Edit profile</span>
                </Link>
                {profile?.is_active ? (
                  <button
                    onClick={handleDeactivate}
                    disabled={activating}
                    className="delete-button"
                  >
                    <span>Deactivate</span>
                    {activating ? (
                      <Loading03Icon className="loading-icon" size={20} />
                    ) : (
                      <UserBlock01Icon size={20} />
                    )}
                  </button>
                ) : (
                  <button
                    onClick={handleActivate}
                    type="button"
                    className="secondary-button"
                  >
                    <span>Activate</span>
                    {activating ? (
                      <Loading03Icon className="loading-icon" size={20} />
                    ) : (
                      <UserCheck01Icon size={20} />
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="personal-info">
            <h2>Person information</h2>
            <div className="profile-items">
              <div className="item">
                <small>First name</small>
                <p>{profile?.first_name || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Last name</small>
                <p>{profile?.last_name || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Date of birth</small>
                <p>{profile?.profile?.date_of_birth || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Gender</small>
                <p>{profile?.profile?.gender || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Age</small>
                <p>{age !== null ? age : "Not provided"}</p>
              </div>
              <div className="item">
                <small>Birth country</small>
                <p>{profile?.profile?.birth_country || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Address</small>
                <p>{profile?.profile?.address || "Not provided"}</p>
              </div>
              <div className="item">
                <small>Phone number</small>
                <p>{profile?.profile?.phone_number || "Not provided"}</p>
              </div>
              <div className="full">
                <small>Permissions</small>
                <div className="permissions">
                  {profile?.permissions?.map((permission, index) => (
                    <p className="permission">{permission}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProfileTabs profile={profile} />
      </div>
    </div>
  ) : (
    <NoResources />
  );
};

const ProfileTabs = ({ profile }) => {
  const [activeTab, setActiveTab] = useState("drafts");
  const { userId } = useParams();
  return (
    <div className="profile-data">
      <div className="tabs-list">
        <div
          onClick={() => setActiveTab("reports")}
          className={`tab ${activeTab === "reports" ? "active" : ""}`}
        >
          <ListViewIcon size={20} />
          <p> Submitted reports</p>
        </div>
        <div
          onClick={() => setActiveTab("drafts")}
          className={`tab ${activeTab === "drafts" ? "active" : ""}`}
        >
          <LayersLogoIcon size={20} />
          <p> Drafts reports</p>
        </div>
        <div
          onClick={() => setActiveTab("complaints")}
          className={`tab ${activeTab === "complaints" ? "active" : ""}`}
        >
          {" "}
          <Sad01Icon size={20} /> <p>Complaints</p>
        </div>
        <div
          onClick={() => setActiveTab("documents")}
          className={`tab ${activeTab === "documents" ? "active" : ""}`}
        >
          <GoogleDocIcon />
          <p>Documents</p>
        </div>
      </div>

      {activeTab === "reports" && (
        <div className="tabs-content">
          <h3>{profile?.first_name && `${profile?.first_name}'s`} reports</h3>
          <ProfileReports userId={userId} />
        </div>
      )}
      {activeTab === "drafts" && (
        <div className="tabs-content">
          <h3>Your drafts</h3>
          <DraftsTab />
        </div>
      )}
      {activeTab === "complaints" && (
        <div className="tabs-content">
          <h3>Your complaints</h3>
          <UserComplaints />
        </div>
      )}
      {activeTab === "documents" && (
        <div className="tabs-content">
          <h3>Your documents</h3>
          <ProfileDocuments />
        </div>
      )}
    </div>
  );
};
const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />{" "}
      <Link className="current-page">Profile</Link>
    </div>
  );
};
const UserProfilePage = () => {
  return (
    <DashBoardContainer
      content={<ProfilePageContent />}
      breadCrumbs={<BreadCrumbs />}
    />
  );
};

export default UserProfilePage;

// permissions check function
// it takes an array of permissions and returns if any of them is in permissions array

const useCheckPermissions = (requiredPermissions) => {
  const [hasPermissions, setHasPermissions] = useState(false);
  const permissions = usePermission();

  // check if permissions has some or any required permissions
  useEffect(() => {
    const hasRequiredPermissions = requiredPermissions.some((permission) =>
      permissions.includes(permission)
    );
    setHasPermissions(hasRequiredPermissions);
  }, [permissions, requiredPermissions]);

  return hasPermissions;
};
