import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api from "../../../../../api";
import toast from "react-hot-toast";
import ModifyEmployeeIncidentForm from "../../../../../components/incidents/forms/modiy/employeeIncident";
import ModifyPageLoader from "./loader";
import ModifyGrievanceInvestigationForm from "../../../../../components/incidents/forms/modiy/modifyGrievanceInvestigation";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";
import NoResources from "../../../../../components/general/noResources";
const ModifyGrievanceInvestigationFoundPageContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchIncident = async () => {
      setIsLoading(true);

      try {
        const response = await api.get(
          `/incidents/grievance-investigation/${incidentId}/`
        );

        if (response.status === 200) {
          setIncident(response.data);
          setIsLoading(false);
          console.log("heeyeeyyeyeye");
          console.log("Real data ", response.data);
        }
      } catch (error) {
        if (error.response.status === 404) {
          setIsError(true);
        }
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncident();
  }, []);

  return isLoading ? (
    <ModifyPageLoader />
  ) : incident && !isError ? (
    <ModifyGrievanceInvestigationForm data={incident} incidentId={incidentId} />
  ) : (
    <NoResources />
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/investigation/"}>
        Grievance Investigation List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/grievance-investigation/${incidentId}/`}>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyGrievanceInvestigationFoundPage = () => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<ModifyGrievanceInvestigationFoundPageContent />}
      breadCrumbs={
        changeBreadCrumbs ? (
          <FacilityBreadCrumbs
            facilityIncidentLink={"grievance-investigation"}
          />
        ) : (
          <BreadCrumbs />
        )
      }
    />
  );
};

export default ModifyGrievanceInvestigationFoundPage;
