import React, { useEffect, useState } from "react";
import { usePermission } from "../../contexts/permissionsContext.jsx";
import SideBar from "../../components/dashaboard/sideBar.jsx";
import PopUp from "../../components/general/popup.jsx";

import "../../assets/css/pages/incidents/incidents_page.css";

// forms
import FormChoicesPopup from "../../components/incidents/forms/incidentFormsChoices.jsx";
import GeneralIncidentForm from "../../components/incidents/forms/generalIncidentForm.jsx";
import LostAndFoundForm from "../../components/incidents/forms/lostAndFound.jsx";
import EmployeeIncidentForm from "../../components/incidents/forms/employeeIncidentForm.jsx";
import MedicationErrorForm from "../../components/incidents/forms/medicationError.jsx";
import GrievanceForm from "../../components/incidents/forms/grievanceForm.jsx";
import DrugReactionForm from "../../components/incidents/forms/drugReactionForm.jsx";
import WorkplaceViolenceIncidentForm from "../../components/incidents/forms/workPlaceViolenceForm.jsx";
import HealthIncidentInvestigationForm from "../../components/incidents/forms/healthIncidentForm.jsx";
import VerbalComplaintForm from "../../components/incidents/forms/verbalComplaintForm.jsx";
import NewContentFullContainer from "../../components/general/newContentFullContainer.jsx";
import GrievanceInvestigationForm from "../../components/incidents/forms/grivanceInvestigationForm.jsx";
import DrugReactionTab from "../../components/incidents/DrugReactionTab.jsx";
import DashBoardContainer from "./dashboardContainer.jsx";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { FacilityBreadCrumbs } from "./incidents/drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage.jsx";

const BreadCrumbs = () => {
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link className="current-page">
        Anaphylaxis/Adverse Drug Reaction List
      </Link>
    </div>
  );
};

function AdverseDrugReactionPage() {
  const permissions = usePermission();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFormChoicesOpen, setIsFormCHoicesOpen] = useState(false);
  const [SelectedForm, setSelectedForm] = useState(false);
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");

  const toggleFormChoicesOpen = () => {
    setIsFormCHoicesOpen(!isFormChoicesOpen);
  };

  const tootlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="dashboard-container">
      <DashBoardContainer
        content={<DrugReactionTab />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityBreadCrumbs facilityIncidentLink={"drug-reaction"} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
}

export default AdverseDrugReactionPage;
