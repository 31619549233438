import React from "react";
import "../../../../assets/css/pages/incidents/medication/medication_details.css";

const WorkPlaceGeneralInfo = ({ data, incidentStatuses }) => {
  console.log("Incident data: ", data);
  return (
    <div className="incident-details">
      <div className="number-mrn">
        <div className="phone-number">
          <small>Incident type</small>
          <h3>{data.incident_type || "Not provided"}</h3>
          <h4>{data.physical_injury_description || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="phone-number">
          <small>
            Detailed description of the observation, threat, incident, or
            activity
          </small>
          <h4>
            {(data.description &&
              data.description.replace(/[^a-zA-Z0-9\s]/g, "").trim()) ||
              "Not provided"}
          </h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Person injured</small>
          <h4>{data.persons_injured?.name || "Not provided"} </h4>
        </div>
        <div className="location">
          <small>Incident witness(es)</small>
          {/* I am using form choices to get some styling, but we will change it */}
          <h4 className="form-choices">
            {data.incident_witness && data.incident_witness.length > 0
              ? data.incident_witness.map((wit, index) => (
                  <span key={index} className="choice">
                    {wit.witness?.user?.first_name}{" "}
                    {wit.witness?.user?.last_name}
                  </span>
                ))
              : "Not provided"}
          </h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Type of contact</small>
          <h4>{data.type_of_contact || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Was the victim alone?</small>
          <h4>{data.victim_was_alone ? "Yes" : "No"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Location</small>
          <h4>{data.location || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Were weapons involved</small>
          <h4>{data.weapons_were_involved ? "Yes" : "No"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Weapon used</small>
          <h4>{data.weapon_used || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Any threats before?</small>
          <h4>{data.there_was_threats_before || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Type of contact</small>
          <h4>{data.type_of_contact || "Not provided"}</h4>
        </div>
        <div className="location">
          <small>Are they any injuries?</small>
          <h4>{data.there_were_injuries || "Not provided"}</h4>
        </div>
      </div>

      <div className="number-mrn">
        <div className="location">
          <small>Termination of incident</small>
          <h4>
            {(() => {
              try {
                const terminationData = JSON.parse(
                  data.termination_of_incident
                );
                return terminationData?.description || "Not provided";
              } catch {
                return "Not provided";
              }
            })()}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default WorkPlaceGeneralInfo;
