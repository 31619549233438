import { Loading02Icon } from "hugeicons-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import api, { API_URL } from "../../api";

const fetchSystemStatus = async () => {
  try {
    const response = await api.get(`${API_URL}/settings/status/`);

    if (response.status === 200) {
      console.log(response.data);
    }

    return response.data;
  } catch (error) {
    if (error.response.status === 403) {
      console.log("forbidden");
      localStorage.removeItem("access");
      window.location.reload();
    }
  }
};

const StatusPage = () => {
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const getStatusContent = () => {
    if (!status) {
      return {
        title: "Loading...",
        description:
          "We are fetching the current status. Please wait a moment.",
      };
    }
    switch (status) {
      case "offline":
        return {
          title: "System Offline",
          description:
            "The system is currently offline for maintenance. We’re working diligently to restore full service as quickly as possible. Thank you for your understanding and patience!",
        };
      case "maintenance":
        return {
          title: "System updates in progress",
          description:
            "We’re currently enhancing the system with the latest updates, including new features, bug fixes, and improvements based on your feedback. Please bear with us, and we’ll notify you as soon as everything is ready. Thank you for your patience!",
        };
      case "coming-soon":
        return {
          title: "Coming Soon",
          description:
            "Exciting updates are on the horizon! We’re working on something new to improve your experience. Stay tuned—great things are coming your way soon!",
        };
      case "error":
      default:
        return {
          title: "Status Unknown",
          description:
            "We're having trouble determining the system status. Please try again later.",
        };
    }
  };

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetchSystemStatus();
        setStatus(response.system_status);

        if (response.system_status === "online") {
          const returnPath = sessionStorage.getItem("returnPath") || "/";
          navigate(returnPath);
        }
      } catch (error) {
        console.error("Error fetching system status:", error);
        setStatus("error");
      }
    };

    checkStatus();
    const interval = setInterval(checkStatus, 30000);

    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(() => {
    if (location.pathname !== "/settings/status" && status !== "online") {
      navigate("/settings/status", { replace: true });
    }
  }, [location.pathname, status, navigate]);

  const statusContent = getStatusContent();

  return (
    <div className="status-main-container">
      <div className="status-wrapper">
        <div className="row">
          <div className="img-container">
            <img src="/images/branding/logo.png" alt="Logo" />
          </div>
          <h1>{statusContent.title}</h1>
        </div>
        <p>{statusContent.description}</p>
        <div className="links">
          <Link className="link">Support</Link>
          <Link to="https://docs.q-control.csrlimited.com/" className="link">
            Documentation
          </Link>
          <Link to="mailto:salphonse@compstaffing.com" className="link">
            Report an error
          </Link>
        </div>
      </div>
    </div>
  );
};

const SystemStatusChecker = ({ children, onStatusChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetchSystemStatus();
        const status = response.system_status;

        if (status === "online") {
          setIsOnline(true);
        } else {
          setIsOnline(false);

          sessionStorage.setItem("returnPath", location.pathname);
          navigate("/settings/status", { replace: true });
        }

        if (onStatusChange) {
          onStatusChange(status);
        }
      } catch (error) {
        console.error("Error checking system status:", error);
        setIsOnline(false);
        navigate("/settings/status", { replace: true });
        if (onStatusChange) {
          onStatusChange("error");
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkStatus();

    const interval = setInterval(checkStatus, 30000);

    return () => clearInterval(interval);
  }, [navigate, location.pathname, onStatusChange]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading02Icon size={40} className="loading-icon" color="#F87C47" />
      </div>
    );
  }

  return isOnline ? children : null;
};

export { StatusPage, SystemStatusChecker };
