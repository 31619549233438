import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../../dashboardContainer";
import IncidentDetailsHeader from "../incidentDetails/incidentDetailsHeader";
import IncidentDetails from "../incidentDetails/incidentDetails";
import IncidentTabs from "../incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../../api";

import "../../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";

import GrievanceInvestationContentTab from "./grivanceInvestionContentTab";
import GrievanceInvestigationGeneraInfo from "./grievanceInvestigationGeneralInfo";

import DrugReactionOtherInformation from "../drugReactionIncident/DrugReactionOtherInformation";
import DrugReactionDocumentHistory from "../drugReactionIncident/DrugReactionDocumentHistory";
import GrivanceInvestigationDocumentHistory from "./grivanceInvestigationDocumentHistory";
import GrievanceInvestigationReviews from "./grievanceInvestigationReviews";
import GrievanceInvestigationOtherInfo from "./grievanceInvestigationOtherInfo";
import GrievanceInvestigationDocument from "./grievanceInvestigationDocument";
import toast from "react-hot-toast";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import NoResources from "../../../../components/general/noResources";

function GrievanceInvestigationDetailsContent() {
  const { grievanceInvestigationId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  //   const [incidentStatus, setIncidentStatus] = useState({});

  useEffect(() => {
    const fetchIncidentDetails = async () => {
      setIsFetching(true);
      try {
        const response = await api.get(
          `${API_URL}/incidents/grievance-investigation/${grievanceInvestigationId}/`
        );
        if (response.status === 200) {
          //   setIncidentStatus(response.data.statuses);
          console.log(response.data);
          setIncidentDetails(response.data);
          setIsFetching(false);
        }
        console.log(incidentDetails);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    fetchIncidentDetails();
  }, []);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/grievance-investigation/${grievanceInvestigationId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${grievanceInvestigationId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : incidentDetails && Object.keys(incidentDetails).length > 0 ? (
        <div className="incident-details">
          <IncidentDetailsHeader
            data={incidentDetails}
            incidentDetailsId={grievanceInvestigationId}
            apiLink={"grievance-investigation"}
            sendTo={"send-to-department"}
            managerAccess={false}
            showClosedManager={false}
          />
          <div className="details">
            <IncidentDetails
              data={incidentDetails}
              // fullName={incidentDetails.patient_name}
              // sex={incidentDetails.sex}
              // IncidentDate={incidentDetails.incident_date}
              // incidentTime={incidentDetails.incident_time}
              // incidentCategory={incidentDetails.patient_type}
              incidentDetails={
                <GrievanceInvestationContentTab data={incidentDetails} />
              }
            />
            <IncidentTabs
              data={incidentDetails}
              //   statuses={incidentStatus}
              generalInformation={
                <GrievanceInvestigationGeneraInfo
                  data={incidentDetails}
                  //   incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <GrievanceInvestigationOtherInfo data={incidentDetails} />
              }
              documentHistory={
                <GrivanceInvestigationDocumentHistory
                  incidentId={grievanceInvestigationId}
                />
              }
              reviews={
                <GrievanceInvestigationReviews
                  incidentId={grievanceInvestigationId}
                />
              }
              documents={
                <GrievanceInvestigationDocument data={incidentDetails} />
              }
            />
          </div>
        </div>
      ) : (
        <NoResources />
      )}
    </div>
  );
}

const BreadCrumbs = () => {
  const { grievanceInvestigationId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/grievance/investigation/"}>
        Grievance Investigation List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{grievanceInvestigationId}</Link>
    </div>
  );
};

function GrievanceInvestigationDetails() {
  return (
    <div>
      <DashBoardContainer
        content={<GrievanceInvestigationDetailsContent />}
        breadCrumbs={<BreadCrumbs />}
      />
    </div>
  );
}

export default GrievanceInvestigationDetails;
