import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import WorkDetails from "./workplaceviolence/workDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import WorkPlaceDetailsContentTab from "./workplaceviolence/workplace";
import WorkPlaceGeneralInfo from "./workplaceviolence/workplaceGeneralInfo";
import WorkPlaceOtherInfo from "./workplaceviolence/workplaceOtherInformation";
import api, { API_URL } from "../../../api";
import toast from "react-hot-toast";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import WorkplaceDocumentHistory from "./workplaceviolence/workplaceDocumentHistory";
import WorkplaceReviews from "./workplaceviolence/workplaceReviews";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import FilesList from "../../../components/documents/filesList";
import { FacilityDetailsBreadCrumbs } from "./DrugReactionDetails";
import NoResources from "../../../components/general/noResources";

const WorkPlaceDetailsContent = () => {
  const { workplace_violence_Id } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});
  const [latestIncidentDetails, setLatestIncidentDetails] = useState({});
  const [useOriginalVersion, setUseOriginalVersion] = useState(true);
  const [currentIncidentData, setCurrentIncidentData] = useState({});

  const fetchIncidentDetails = async () => {
    setIsFetching(true);
    try {
      let response;

      // Fetch the original version of the incident
      if (useOriginalVersion) {
        response = await api.get(
          `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/`
        );
        setIncidentDetails(response.data); // Store the original data
        setCurrentIncidentData(response.data); // Set current data for UI
        console.log(response.data);
      } else {
        // Fetch the latest modified version of the incident
        const res = await api.get(
          `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/`
        );
        const latestIncident = res.data.modifications.versions.find((mod) => {
          return mod.latest === true;
        });

        if (latestIncident) {
          response = await api.get(
            `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/versions/${latestIncident.id}/`
          );
          console.log(response.data);
          console.log(latestIncident);
        } else {
          response = res;
        }

        setLatestIncidentDetails(response.data); // Store the latest modified version
        setCurrentIncidentData(response.data); // Set current data for UI
      }

      setIsFetching(false); // Stop loading state
    } catch (error) {
      console.error("Error fetching incident details:", error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchIncidentDetails(); // Fetch incident data when version toggles or incidentId changes
  }, [workplace_violence_Id, useOriginalVersion]);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/${workplace_violence_Id}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${workplace_violence_Id}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : incidentDetails && Object.keys(incidentDetails).length > 0 ? (
        <div className="incident-details">
          {incidentDetails.modifications ? (
            <IncidentDetailsHeader
              data={
                useOriginalVersion ? incidentDetails : latestIncidentDetails
              }
              incidentDetailsId={workplace_violence_Id}
              apiLink={"workplace_violence"}
              sendTo={"send-to-department"}
              managerAccess={true}
              useOriginalVersion={useOriginalVersion}
              setCurrentIncidentData={setCurrentIncidentData}
              showClosedManager={true}
            />
          ) : (
            ""
          )}

          <div className="details">
            <WorkDetails
              data={currentIncidentData}
              fullName={
                currentIncidentData.reported_by
                  ? `${currentIncidentData.reported_by?.user?.last_name} ${currentIncidentData.reported_by?.user?.first_name}`
                  : null
              }
              /* sex={incidentDetails.sex} */
              IncidentDate={currentIncidentData.date_of_incident}
              incidentTime={currentIncidentData.time_of_incident}
              incidentCategory={currentIncidentData.incident_type}
              incidentDetails={
                <WorkPlaceDetailsContentTab data={currentIncidentData} />
              }
            />
            <IncidentTabs
              data={currentIncidentData}
              statuses={incidentStatus}
              generalInformation={
                <WorkPlaceGeneralInfo
                  data={currentIncidentData}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <WorkPlaceOtherInfo data={currentIncidentData} />
              }
              documentHistory={
                <WorkplaceDocumentHistory incidentId={workplace_violence_Id} />
              }
              reviews={<WorkplaceReviews incidentId={workplace_violence_Id} />}
              documents={
                <IncidentDocuments incidentId={workplace_violence_Id} />
              }
            />
          </div>
        </div>
      ) : (
        <NoResources />
      )}
    </div>
  );
};

const BreadCrumbs = () => {
  const { workplace_violence_Id } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/workplace-violence/"}>
        Workplace Violence List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{workplace_violence_Id}</Link>
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/workplace_violence/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
          localStorage.setItem("incidentDocumentCount", response.data.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};

const WorkPlaceDetails = () => {
  const { workplace_violence_Id } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <div>
      <DashBoardContainer
        content={<WorkPlaceDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={workplace_violence_Id} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
};

export default WorkPlaceDetails;
