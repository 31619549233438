import { Download04Icon, File01Icon } from "hugeicons-react";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

async function handleDownloadPdf(id) {
  const html2pdf = await require("html2pdf.js");
  const element = document.querySelector("#generate-report");
  html2pdf(element, {
    margin: 0,
    filename: `general-incident-${id.incidentId}.pdf`,
  });
}
const GenerateReport = () => {
  const incidentId = useParams();
  console.log(incidentId);
  return (
    <>
      <style>
        {`

  .incident-details-header .preview-popup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F87C47;
    background-color: #f5f5f5;
  }
  .incident-details-header .preview-popup::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  .incident-details-header .preview-popup::-webkit-scrollbar-thumb {
    background-color: #4d7fad;
    border-radius: 10px;
  }


`}
      </style>

      <div className="incident-popup">
        <div
          className="preview-popup"
          style={{
            backgroundColor: "#FBFBFB",

            width: "100%",
            minWidth: "300px",
            overflowY: "auto",
            position: "relative",
          }}
          id="generate-report"
        >
          <div
            className="preview-popup-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              padding: "2rem",
              width: "100%",
              backgroundColor: "#ffffff",
            }}
          >
            <div
              className="prev-col"
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div
                className="prev-col title-date"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <h4>General Incident Report</h4>
                <small>09/12/2023, 10:00:00</small>
              </div>
              <div
                className="prev-row"
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div
                  className="prev-col status"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h4 style={{ color: "#909090", fontSize: "12px" }}>Status</h4>
                  <small
                    style={{
                      padding: "4px 24px",
                      backgroundColor: "#f3f3f3",
                      color: "#145C9E",
                      borderRadius: "4px",
                    }}
                  >
                    Open
                  </small>
                </div>
                <div
                  className="prev-col current-step"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h4 style={{ color: "#909090", fontSize: "12px" }}>
                    Current step
                  </h4>
                  <small style={{ color: "#145C9E", padding: "4px 24px" }}>
                    Step 1/2
                  </small>
                </div>
              </div>
            </div>
            <div
              className="prev-col"
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div
                className="prev-col facility"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Facility</small>
                <h4 style={{ fontWeight: "500", fontSize: "12px" }}>
                  Pawhuska Hospital
                </h4>
              </div>
              <div
                className="prev-col department"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Department</small>
                <h4 style={{ fontWeight: "500", fontSize: "12px" }}>
                  Emergency
                </h4>
              </div>
            </div>
            <div
              className="logo-container"
              style={{ height: "30px", width: "75px", alignSelf: "flex-start" }}
            >
              <img
                src="/images/branding/logo.png"
                alt="Logo"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div
            className="popup-content preview-popup-content"
            style={{
              padding: "20px 30px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div
              className="preview-personal-info"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "10px",
              }}
            >
              <div
                className="prev-col prev-col-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="prev-col name"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h5>Harvey Barnes</h5>
                  <small>reviewer@example.com</small>
                  <small
                    className="category"
                    style={{
                      padding: "4px 24px",
                      backgroundColor: "#f3f3f3",
                      borderRadius: "4px",
                      width: "fit-content",
                      color: "#474747",
                    }}
                  >
                    In patient
                  </small>
                </div>
                <div
                  className="prev-col city"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>City</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Anytown
                  </h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col phone"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Phone number</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    0783450958
                  </h4>
                </div>
                <div
                  className="prev-col age"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Patient age</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>24</h4>
                </div>
                <div
                  className="prev-col state"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>State</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Anytown
                  </h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col mrn"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>MRN</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    MRN 390-7800
                  </h4>
                </div>
                <div
                  className="prev-col dob"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Date of birth</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    09/12/2023
                  </h4>
                </div>
                <div
                  className="prev-col zip"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Zip</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>12345</h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col gender"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Gender</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>Male</h4>
                </div>
                <div
                  className="prev-col addr"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Address</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    123 Main Street
                  </h4>
                </div>
                <div
                  className="prev-col country"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Country</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>USA</h4>
                </div>
              </div>
            </div>
            <div
              className="preview-other-info"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "10px",
              }}
            >
              <div
                className="prev-col prev-col-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Incident Location</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Elevator
                  </h4>
                </div>
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Contributing Factors</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Hypertension
                  </h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Consulting diagonosis</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Hypertension
                  </h4>
                </div>
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Outcome</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    No apparent injury
                  </h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Medicine</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Amiodipine
                  </h4>
                </div>
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Patient/visitor status prior to incident</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Agitated
                  </h4>
                </div>
              </div>
              <div
                className="prev-col prev-col-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <small>Other status</small>
                  <h4 style={{ fontSize: "12px", fontWeight: "500" }}>
                    Agitated
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="prev-row prev-row-2"
              style={{ display: "flex", flexDirection: "row", gap: "20px" }}
            >
              <div
                className="incident-type"
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "8px",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                  Incident type
                </h4>
                <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                  Fall related incident
                </h4>
                <div
                  className="prev-row"
                  style={{ display: "flex", flexDirection: "row", gap: "15px" }}
                >
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <small>Fall type</small>
                    <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                      Found on floor
                    </h4>
                  </div>
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <small>Morse fall score</small>
                    <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                      24 Points
                    </h4>
                  </div>
                </div>
                <div
                  className="prev-col"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                    Status prior to incident
                  </h4>
                  <small>Side rails up</small>
                </div>
              </div>
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div
                  className="notified physician-notified"
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    borderRadius: "8px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                    Physician notified
                  </h4>
                  <div
                    className="prev-row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="prev-col-1"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Name of physician</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          Steve walker
                        </h4>
                      </div>
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Email</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          steve@gmail.com
                        </h4>
                      </div>
                    </div>
                    <div
                      className="prev-col-2"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Phone number</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          123-456-7897
                        </h4>
                      </div>
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Date/Time Notified</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          17/07/2023, 10:00 AM
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="notified family-notified"
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "20px",
                    borderRadius: "8px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                    Family notified
                  </h4>
                  <div
                    className="prev-row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="prev-col-1"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Name of family</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          Steve walker
                        </h4>
                      </div>
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Email</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          steve@gmail.com
                        </h4>
                      </div>
                    </div>
                    <div
                      className="prev-col-2"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Phone number</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          123-456-7897
                        </h4>
                      </div>
                      <div
                        className="prev-col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <small>Date/Time Notified</small>
                        <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                          17/07/2023, 10:00 AM
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="summary"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                  Brief summary of incident
                </h4>
                <small>
                  John Doe fell on the floor in Room 101. Dr. Jane Smith and
                  family member were notified.
                </small>
              </div>
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <h4 style={{ fontSize: "12px", fontWeight: 500 }}>Response</h4>
                <small>The incident has been logged and reviewed.</small>
              </div>
            </div>
            <div
              className="action-taken"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                  Immediate action taken
                </h4>
                <small>
                  Hospital inspected all critical medical equipment, revised
                  maintenance protocols, implemented additional staff training,
                  established regular audits of maintenance records, and
                  notified Philips Healthcare about the battery issue.
                </small>
              </div>
            </div>
            <div
              className="reviews"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <h4>Reviews</h4>
              <div
                className="reviews-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="prev-col review"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    backgroundColor: "#fbfbfb",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    className="prev-row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                      Harvey James
                    </h4>
                    <small>Quality manager</small>
                    <small>reviewer@example.com</small>
                  </div>
                  <p style={{ fontSize: "12px", color: "#585050" }}>
                    Venenatis nulla cras vitae et malesuada hac sed id. Nibh
                    congue adipiscing vel viverra fermentum nullaIn elit ac
                    integer adipiscing rutrum nam risus congue.
                  </p>

                  <small>12/07/2023, 10:00 AM</small>
                </div>
                <div
                  className="prev-col review"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    backgroundColor: "#fbfbfb",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    className="prev-row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ fontSize: "12px", fontWeight: 500 }}>
                      Harvey James
                    </h4>
                    <small>Quality manager</small>
                    <small>reviewer@example.com</small>
                  </div>
                  <p style={{ fontSize: "12px", color: "#585050" }}>
                    Venenatis nulla cras vitae et malesuada hac sed id. Nibh
                    congue adipiscing vel viverra fermentum nullaIn elit ac
                    integer adipiscing rutrum nam risus congue.
                  </p>

                  <small>12/07/2023, 10:00 AM</small>
                </div>
              </div>
            </div>
            <div
              className="documents"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <h4>Documents</h4>
              <div
                className="document-container prev-row"
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div
                  className="document prev-row"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    backgroundColor: "#fbfbfb",
                    padding: "20px",
                    borderRadius: "8px",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <File01Icon size={24} color={"#145C9E"} variant={"stroke"} />
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <h4 style={{ fontWeight: 500 }}>
                      Certified Receipt Received
                    </h4>
                    <small>Oct 12, 2023, Pdf</small>
                  </div>
                </div>
                <div
                  className="document prev-row"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    backgroundColor: "#fbfbfb",
                    padding: "20px",
                    borderRadius: "8px",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <File01Icon size={24} color={"#145C9E"} variant={"stroke"} />
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <h4 style={{ fontWeight: 500 }}>
                      Certified Receipt Received
                    </h4>
                    <small>Oct 12, 2023, Pdf</small>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="created-by prev-row"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                justifyContent: "space-between",
              }}
            >
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Created by</small>
                <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                  Admin user
                </h4>
              </div>
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Created at</small>
                <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                  Oct 12, 2023, 10:00 AM
                </h4>
              </div>
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Updated by</small>
                <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                  Admin user
                </h4>
              </div>
              <div
                className="prev-col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <small>Updated at</small>
                <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                  Oct 12, 2023, 10:00 AM
                </h4>
              </div>
            </div>

            <div
              className="assignees"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <h4>Assignees</h4>
              <div
                className="assignee-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="assignee prev-row"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    backgroundColor: "#fbfbfb",
                    padding: "20px",
                    borderRadius: "8px",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <small>Dr. Jane Smith</small>
                    <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                      jane@example.com
                    </h4>
                  </div>
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <small>Position</small>
                    <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                      Quality Manager / Chief Advisor
                    </h4>
                  </div>
                  <div
                    className="prev-col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <small>Phone number</small>
                    <h4 style={{ fontWeight: 500, fontSize: "12px" }}>
                      123-456-7890
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="download-container"
            style={{
              position: "sticky",
              bottom: "15px",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "flex-end",
            }}
            data-html2canvas-ignore
          >
            <button
              style={{
                backgroundColor: "#F87C47",
                color: "#ffffff",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                padding: "10px 20px",
                marginRight: "15px",
                fontSize: "14px",
                fontWeight: 400,
              }}
              className="download-btn"
              onClick={() => handleDownloadPdf(incidentId)}
            >
              <Download04Icon size={20} color={"#ffffff"} variant={"stroke"} />
              <span>Download</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateReport;
