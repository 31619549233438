import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import DashBoardContainer from "../../../dashboardContainer";
import api, { API_URL } from "../../../../../api";
import ModifyWorkplaceIncident from "../../../../../components/incidents/forms/modiy/workplaceIncident";
import { ArrowLeft01Icon, ArrowTurnBackwardIcon } from "hugeicons-react";
import BackToPage from "../../../../../components/incidents/backToPage";
import { ArrowRight01Icon } from "hugeicons-react";
import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage";
import NoResources from "../../../../../components/general/noResources";

const ModifyWorkplaceIncidentPageContent = () => {
  const [error, setError] = useState();
  const [incident, setIncident] = useState({});
  const { incidentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/workplace_violence/${incidentId}/`
        );

        if (response.status === 200) {
          console.log(response.data.incident);
          setIncident(response.data.incident);
          setIsLoading(false);
        }
      } catch (error) {
        if (error.response.status === 404) {
          setIsError(true);
        }
        setError("Error getting incident data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncidentData();
  }, []);

  return isLoading ? (
    "Gettting data..."
  ) : incident && !isError ? (
    <div>
      (
      <ModifyWorkplaceIncident data={incident} incidentId={incidentId} />)
    </div>
  ) : (
    <NoResources />
  );
};
const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/workplace-violence/"}>
        Work Place Violence List
      </Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/workplace_violence/${incidentId}/`}>
        #{incidentId}
      </Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};
const ModifyWorkplaceIncidentPage = () => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<ModifyWorkplaceIncidentPageContent />}
      breadCrumbs={
        changeBreadCrumbs ? (
          <FacilityBreadCrumbs facilityIncidentLink={"workplace_violence"} />
        ) : (
          <BreadCrumbs />
        )
      }
    />
  );
};

export default ModifyWorkplaceIncidentPage;
