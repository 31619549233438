import React, { useEffect, useState } from "react";
import api, { cleanedData } from "../../../../api";
import CustomSelectInput from "../inputs/customSelect";
import {
  Cancel01Icon,
  CheckmarkSquare01Icon,
  FloppyDiskIcon,
  InformationCircleIcon,
  Loading02Icon,
  LockIcon,
  PlusSignIcon,
  SecurityPasswordIcon,
  SquareIcon,
  SquareLockPasswordIcon,
  UserIcon,
} from "hugeicons-react";
import { permissionsList } from "../../../../constants";
import CustomDatePicker from "../inputs/datePicker";

const NewUserForm = () => {
  const [activeTab, setActiveTab] = useState("basic");
  const [showDepartmentsChoices, setShowDepartmentsChoices] = useState(false);
  const [showRolesContainer, setShowRolesContainer] = useState(false);

  const [selectedFacility, setSelectedFacility] = useState();
  const [facilities, setFacilities] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [fetchingFacilities, setFetchingFacilities] = useState(false);
  const [permissionFacility, setPermissionFacility] = useState({});

  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [fetchingDepartments, setFetchingDepartments] = useState(true);
  const [positions, setPositions] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");

  const [department, setDepartment] = useState({});
  const [accessDepartments, setAccessDepartments] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [userRole, setUserRole] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [birthCountry, setBirthCountry] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState();

  const handleSetDepartment = (department) => {
    console.log("selected department", department);
    setDepartment(department);
  };

  useEffect(() => {
    //fetchPositions
    const fetchPositions = async () => {
      try {
        const response = await api.get("/accounts/permissions/all/");
        setPositions(response.data.permissions);
        console.log("positions", response.data.permissions);
      } catch (error) {
        console.error(error);
        setErrorMessage("Error getting positions");
      }
    };
    fetchPositions();
  }, []);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        setFetchingFacilities(true);
        const response = await api.get("/facilities/");
        if (response.status === 200) {
          setFacilities(response.data.data);
          console.log("facilities", response.data);
        }
        setFetchingFacilities(false);
      } catch (error) {
        setFetchingFacilities(false);
      }
    };
    fetchFacilities();
  }, []);

  const fetchDepartments = async (facilityId) => {
    if (facilityId) {
      try {
        setFetchingDepartments(true);
        const response = await api.get(
          `/facilities/${facilityId}/departments/`
        );
        if (response.status === 200) {
          setDepartments(response.data.departments);
          console.log(response.data.departments);
          setFetchingDepartments(false);
        }
      } catch (error) {
        console.error(error);
        setFetchingDepartments(false);
      }
    }
  };

  const fetchAccessDepartments = async (facilityId) => {
    if (facilityId) {
      try {
        setFetchingDepartments(true);
        const response = await api.get(
          `/facilities/${facilityId}/departments/`
        );
        if (response.status === 200) {
          setAccessDepartments(response.data.departments);
          console.log(response.data.departments);
          setFetchingDepartments(false);
        }
      } catch (error) {
        console.error(error);
        setFetchingDepartments(false);
      }
    }
  };
  const handleSelectedUserFacility = (facility) => {
    setSelectedFacility(facility);
    console.log(facility);
    fetchDepartments(facility.id);
  };

  const handleAccessToFacilities = (facility) => {
    if (!selectedFacilities.includes(facility)) {
      setSelectedFacilities([...selectedFacilities, facility]);
      setShowDepartmentsChoices(true);
      fetchAccessDepartments(facility.id);
      setPermissionFacility(facility);
    } else {
      setSelectedFacilities(
        selectedFacilities.filter((prevFacility) => prevFacility !== facility)
      );
    }
  };

  const handleAccessToDepartments = (department) => {
    if (!selectedDepartments.includes(department)) {
      setSelectedDepartments([...selectedDepartments, department]);
      console.log("Selected access to department", department);
      const fac = permissions.filter(
        (prevFac) => (prevFac = permissionFacility)
      );
    } else {
      setSelectedDepartments(
        selectedDepartments.filter(
          (prevDepartment) => prevDepartment != department
        )
      );
    }
  };

  const handleNewUser = async () => {
    const departmentId = departments.find(
      (dept) => dept.name === department
    )?.id;

    // if (!firstName || !lastName || !email || !userRole || !department) {
    //     setErrorMessage("First name, email, position, department and facility are required ")
    //     return
    // }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,

      gender: gender,
      date_of_birth: dateOfBirth,
      address: address,
      city: city,
      zip_code: zipCode,
      birth_country: birthCountry,

      facility: selectedFacility,
      department_id: department.id,
      role: userRole,
      permissions: [
        { facilities: selectedFacilities },
        { departments: selectedDepartments },
      ],
      password: password,
    };

    console.log(userData);

    try {
      setIsLoading(true);
      const response = await api.post(
        "/accounts/users/new/",
        cleanedData(userData)
      );
      if (response.status === 201) {
        setSuccessMessage("User is added successfully");
        setIsLoading(false);
        console.log("user data: ", response.data);
        setTimeout(() => {
          setSuccessMessage("");
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message ||
            error.response.data.error ||
            "Error while creating a new user"
        );
      } else {
        setErrorMessage("Unknown error creating a new user");
      }

      console.log(department.id);

      console.error(error);
      setIsLoading(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  const handleShowDepartmentChoices = (facilityId) => {
    setShowDepartmentsChoices(true);
    setSelectedFacility(facilityId);
  };

  const handleSetPermissions = () => {
    setShowDepartmentsChoices(false);
  };

  return (
    <div className="form">
      {showRolesContainer ? (
        <RolesContainer setShowRolesContainer={setShowRolesContainer} />
      ) : (
        <form>
          <div className="form-tabs">
            <div
              onClick={() => setActiveTab("basic")}
              className={`tab ${activeTab === "basic" ? "active" : ""}`}
            >
              <UserIcon />
              <span>Basic info</span>
            </div>
            <div
              onClick={() => setActiveTab("other")}
              className={`tab ${activeTab === "other" ? "active" : ""}`}
            >
              <InformationCircleIcon />
              <span>Other info</span>
            </div>
            <div
              onClick={() => setActiveTab("permissions")}
              className={`tab ${activeTab === "permissions" ? "active" : ""}`}
            >
              <SquareLockPasswordIcon />
              <span>Permissions</span>
            </div>
            <div
              onClick={() => setActiveTab("security")}
              className={`tab ${activeTab === "security" ? "active" : ""}`}
            >
              <SecurityPasswordIcon />
              <span>Security</span>
            </div>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {activeTab === "basic" && (
            <>
              {" "}
              <h3>Basic user information</h3>
              <div className="half">
                <div className="field">
                  <label htmlFor="">
                    First name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Enter user first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="">
                    Last name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Enter user last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="half">
                <div className="field">
                  <label htmlFor="">
                    Email <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter user email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="">Phone number</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Enter user phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="half"></div>
            </>
          )}
          {activeTab === "other" && (
            <>
              {" "}
              <h3>Other user information</h3>
              <div className="half">
                <div className="field">
                  <label htmlFor="">Sex</label>
                  <CustomSelectInput
                    options={["Male", "Female", "Other"]}
                    placeholder={"Select sex"}
                    selected={gender}
                    setSelected={setGender}
                  />
                </div>
                <div className="field">
                  <label htmlFor="">Date of birth</label>
                  <CustomDatePicker
                    selectedDate={dateOfBirth}
                    setSelectedDate={setDateOfBirth}
                  />
                </div>
              </div>
              <div className="half">
                <div className="field">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter user address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="">Birth country</label>
                  <input
                    type="text"
                    name="birthCountry"
                    id="birthCountry"
                    placeholder="Enter user birth country"
                    value={birthCountry}
                    onChange={(e) => setBirthCountry(e.target.value)}
                  />
                </div>
              </div>
              <div className="half">
                <div className="field">
                  <label htmlFor="">City</label>
                  <input
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    type="text"
                    name="city"
                    id="city"
                    placeholder="Enter user city"
                  />
                </div>

                <div className="field">
                  <label htmlFor="">Zip code</label>
                  <input
                    onChange={(e) => setZipCode(e.target.value)}
                    value={zipCode}
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    placeholder="Enter user zip code"
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === "permissions" && (
            <>
              {" "}
              {showDepartmentsChoices ? (
                <>
                  <h4>User permissions for {permissionFacility.name}</h4>
                  <p>Choose which department(s) this user will access to</p>
                  <div className="form-choices">
                    {departments &&
                      departments.map((department, index) => (
                        <div
                          key={index}
                          onClick={() => handleAccessToDepartments(department)}
                          className={`choice ${
                            selectedDepartments.includes(department)
                              ? "checked"
                              : ""
                          }`}
                        >
                          {selectedDepartments &&
                          selectedDepartments.includes(department) ? (
                            <CheckmarkSquare01Icon />
                          ) : (
                            <SquareIcon />
                          )}
                          <span>{department.name}</span>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <h4>User permissions</h4>
                  <div className="field">
                    <label htmlFor="">
                      Facility user belongs to <span>*</span>{" "}
                    </label>
                    <div className="form-choices">
                      {facilities &&
                        facilities.map((facility, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelectedUserFacility(facility)}
                            className={`choice ${
                              selectedFacility === facility ? "selected" : ""
                            }`}
                          >
                            {selectedFacility === facility ? (
                              <CheckmarkSquare01Icon />
                            ) : (
                              <SquareIcon />
                            )}
                            <span>{facility.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="">
                      Department user belongs to <span>*</span>{" "}
                    </label>
                    {fetchingDepartments ? (
                      "Fetching departments..."
                    ) : (
                      <select
                        name="department"
                        id="department"
                        onChange={(e) =>
                          handleSetDepartment(
                            departments.find(
                              (dept) =>
                                parseInt(dept.id) === parseInt(e.target.value)
                            )
                          )
                        }
                        value={department.id}
                      >
                        <option>Select department</option>
                        {departments &&
                          departments.map((depart) => {
                            return (
                              <option value={depart.id} key={depart.id}>
                                {depart.name}
                              </option>
                            );
                          })}
                      </select>
                    )}

                    {/* <CustomSelectInput selected={department} setSelected={setDepartment} options={departments.map(department => department.name)} /> */}
                  </div>

                  <div className="field">
                    <label htmlFor="">
                      Position <span>*</span>{" "}
                      <span
                        onClick={() => setShowRolesContainer(true)}
                        className="inline-link"
                      >
                        Learn more
                      </span>{" "}
                    </label>
                    <div className="form-choices">
                      {positions &&
                        positions.map((role, index) => (
                          <div
                            key={index}
                            onClick={() => setUserRole(role)}
                            className={`choice ${
                              userRole === role ? "selected" : ""
                            }`}
                          >
                            {userRole === role ? (
                              <CheckmarkSquare01Icon />
                            ) : (
                              <SquareIcon />
                            )}
                            <span>{role}</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  <h3>Select which facilities this user has access to</h3>
                  <div className="hospitals form-choices">
                    {facilities &&
                      facilities.map((facility, index) => (
                        <div
                          onClick={() => handleAccessToFacilities(facility)}
                          key={index}
                          className={`choice ${
                            selectedFacilities.includes(facility)
                              ? "selected"
                              : ""
                          }`}
                        >
                          {selectedFacilities &&
                          selectedFacilities.includes(facility) ? (
                            <CheckmarkSquare01Icon />
                          ) : (
                            <SquareIcon />
                          )}
                          <span>{facility.name}</span>
                          {/* <span>| 5</span> */}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          )}
          {activeTab === "security" && (
            <>
              <h3>Security Notice</h3>
              <InformationCircleIcon />
              <p>
                New users will be assigned a password automatically, and it will
                be sent in their email. We recommend you leave it that way. In
                case you need to, you can assign them a password using this form
              </p>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="User password"
              />
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm password"
              />
            </>
          )}
        </form>
      )}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      {showDepartmentsChoices ? (
        <button
          onClick={handleSetPermissions}
          type="button"
          className="secondary-button"
        >
          <PlusSignIcon />
          <span>Set permissions</span>
        </button>
      ) : (
        !showRolesContainer && (
          <>
            <button
              disabled={isLoading}
              onClick={handleNewUser}
              type="button"
              className="primary-button"
            >
              {isLoading ? (
                <>
                  <Loading02Icon size={20} className="loading-icon" /> Saving
                </>
              ) : (
                <>
                  <FloppyDiskIcon size={20} />
                  Add user
                </>
              )}
            </button>
          </>
        )
      )}
    </div>
  );
};

export default NewUserForm;

const RolesContainer = ({ setShowRolesContainer }) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get("/accounts/constants/");
        if (response.status === 200) {
          console.log("permissions: ", response.data.data.permissions);
          const flattenedPermissions = response.data.data.permissions.flat();
          setRoles(flattenedPermissions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchRoles();
  }, []);

  return (
    <div className="roles-container">
      <h2>Roles details</h2>
      {roles.length ? (
        <div className="roles">
          {roles.map((perm, index) => (
            <div key={index} className="role">
              <h4>{perm.name}</h4>
              <p>{perm.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No roles available</p>
      )}
      <div
        onClick={() => setShowRolesContainer(false)}
        className="secondary-button hide-roles"
      >
        <Cancel01Icon />
        <span>Close roles</span>
      </div>
    </div>
  );
};
