import { Loading02Icon } from "hugeicons-react";
import React, { useState } from "react";
import LogoText from "../../components/branding/logoText";
import SupportLink from "./supportLink";
import axios from "axios";
import { API_URL } from "../../api";

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSupportLink, setShowSupportLink] = useState(false);

  const handleSendResetCode = async () => {
    setShowSupportLink(false);
    if (!userEmail) {
      setErrorMessage("Please enter your email address");
      setShowSupportLink(true);
      return;
    }
    setIsLoading(true);
    try {
      const emailData = {
        email: userEmail,
      };

      const response = await axios.post(
        `${API_URL}/accounts/password-reset-request/`,
        emailData
      );
      if (response.status === 200) {
        localStorage.setItem("restPasswordUserEmail", userEmail);
        setIsLoading(false);
        setSuccessMessage("Check your email for the reset code");
        setTimeout(() => {
          window.location.href = "/forgot-password/new-password/";
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        setErrorMessage(
          error.response.message ||
            error.response.error ||
            "Failed to send reset code"
        );
      } else {
        setErrorMessage("Unknown error while verifying email");
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-pages">
      <div className="form">
        <LogoText text={"Forgot password"} />
        <h1>Forgot password?</h1>
        <p>
          Let's help you recover it. Enter your email for verification. We will
          send you a code in the email, which you need to copy and paste it in
          the next page
        </p>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        <input
          onChange={(e) => setUserEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <button onClick={handleSendResetCode} type="button">
          {" "}
          {isLoading ? (
            <Loading02Icon size={20} className="loading-icon" />
          ) : (
            "Send code"
          )}
        </button>
      </div>

      {showSupportLink && <SupportLink />}
    </div>
  );
};

export default ForgotPassword;
