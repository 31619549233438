import React, { useState, useEffect } from "react";
import CustomModifiedSelectInput from "../../../../components/incidents/forms/inputs/customModifiedSelect";
import "../../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import {
  ArrowRight01Icon,
  ArrowDown01Icon,
  MailSend02Icon,
  DocumentValidationIcon,
  CheckmarkCircle02Icon,
  House01Icon,
  DepartementIcon,
  PencilEdit02Icon,
  CommentAdd01Icon,
  FileExportIcon,
  Download04Icon,
  File01Icon,
} from "hugeicons-react";

import SendToDepartmentForm from "../../../../components/incidents/forms/sendToDepartmentForm";
import ReviewForm from "../../../../components/incidents/forms/reviewFrom";
import MarkResolvedForm from "../../../../components/incidents/forms/markResolvedForm";
import api from "../../../../api";
import { API_URL } from "../../../../api";
import { usePermission } from "../../../../contexts/permissionsContext";
import ReviewFrom from "../../../../components/incidents/forms/reviewFrom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import GenerateReport from "../../../../components/general/popups/generateReport";

const IncidentDetailsHeader = ({
  data,
  incidentDetailsId,
  setCurrentIncidentData,
  useOriginalVersion,
  sendTo,
  apiLink,
  showClosedManager,
}) => {
  // Default to "Most Recent" if available, otherwise "Original Version"
  const permissions = usePermission();
  const defaultVersion = data?.modifications?.versions?.find(
    (mod) => mod.latest
  )
    ? "Most Recent"
    : "Original Version";
  const [selectedVersion, setSelectedVersion] = useState(defaultVersion);
  const [showActions, setShowActions] = useState(false);
  const [showSendToDepartmentForm, setShowSendToDepartmentForm] =
    useState(false);
  const [showMarkResolvedPopup, setShowMarkResolvedPopup] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showPreviewForm, setShowPreviewForm] = useState(false);
  const canModifyDraft = localStorage.getItem("canModifyDraft");
  const canViewDraft = localStorage.getItem("canViewDraft");
  // Helper to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours < 12 ? "AM" : "PM";
    hours = hours % 12 || 12;
    return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
  };

  // Map and format version options
  const sortedVersions = data?.modifications?.versions?.map((mod, index) =>
    mod.original === true // Define the original version
      ? {
          label: `<span style="font-weight: bold; margin-right: 10px;">Original</span> <span style="color: gray;">${formatDate(
            mod.date
          )}</span>`,
          value: "Original Version",
          id: incidentDetailsId,
          isOriginal: true,
          isMostRecent: mod.latest,
          date: mod.date,
        }
      : {
          label: `<span style="font-weight: bold; margin-right: 10px;">Modified</span> <span style="color: gray;">${formatDate(
            mod.date
          )}</span>`,
          value: `Modified Version ${index}`,
          id: mod.id,
          date: mod.date,
          isOriginal: false,
          isMostRecent: mod.latest,
        }
  );

  // Identify the most recent version
  const mostRecentVersion = sortedVersions?.find(
    (version) => version.isMostRecent
  );
  if (mostRecentVersion) {
    mostRecentVersion.label = `<span style="font-weight: bold; margin-right: 10px;">Most Recent</span> <span style="color: gray;">${formatDate(
      mostRecentVersion.date
    )}</span>`;
    mostRecentVersion.value = "Most Recent";
  }

  // Define the options array with dynamic positioning of "Most Recent"

  const getUpdatedOptions = () => {
    if (selectedVersion === "Most Recent") {
      return [
        mostRecentVersion,

        ...sortedVersions?.filter((v) => v !== mostRecentVersion),
      ];
    } else if (selectedVersion === "Original Version") {
      return [
        mostRecentVersion,
        ...sortedVersions?.filter((v) => v !== mostRecentVersion),
      ];
    } else {
      const selectedOption = sortedVersions.find(
        (v) => v.value === selectedVersion
      );
      return [
        selectedOption,
        mostRecentVersion,

        ...sortedVersions.filter(
          (v) => v !== mostRecentVersion && v !== selectedOption
        ),
      ];
    }
  };

  // Memoize options to prevent unnecessary re-renders

  const [updatedOptions, setUpdatedOptions] = useState(getUpdatedOptions);

  // Update options and fetch data when selected version changes
  useEffect(() => {
    setUpdatedOptions(getUpdatedOptions);

    const fetchIncidentData = async () => {
      const selectedOption = updatedOptions.find(
        (option) => option.value === selectedVersion
      );

      // Validate that selectedOption exists
      if (!selectedOption) return;

      const url = selectedOption.isOriginal
        ? `/incidents/${apiLink}/${incidentDetailsId}/versions/original/`
        : `/incidents/${apiLink}/${incidentDetailsId}/versions/${selectedOption.id}/`;

      try {
        const response = await api.get(`${API_URL}${url}`);
        setCurrentIncidentData(
          selectedOption.isOriginal ? response.data : response.data
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIncidentData();
  }, [selectedVersion, incidentDetailsId, setCurrentIncidentData]);

  const toggleShowMarkResolvedPopup = () =>
    setShowMarkResolvedPopup(!showMarkResolvedPopup);
  const toggleShowReviewForm = () => setShowReviewForm(!showReviewForm);
  const toggleShowPreviewForm = () => setShowPreviewForm(!showPreviewForm);
  const toggleActions = () => setShowActions(!showActions);
  const toggleShowSendToDepartmentForm = () =>
    setShowSendToDepartmentForm(!showSendToDepartmentForm);

  return (
    <div className="incident-details-header">
      <div className="incident-steps-and-type">
        <div className="details">
          <h3>#{incidentDetailsId}</h3>
          <div className="incident-type">
            <span>Incident details</span>{" "}
            <div className="type">
              {data.incident.incident_type || "Not provided"}
            </div>
          </div>
        </div>

        <div className="facility-header">
          <div className="facility">
            <div className="facility-name">
              <House01Icon color="gray" />
              <span>Facility:</span>
            </div>
            <span>{data.incident.report_facility?.name || "No facility"}</span>
          </div>
          <div className="department">
            <div className="department-name">
              <DepartementIcon color="gray" />
              <span>Department:</span>
            </div>
            <span>{data.incident?.department || "No department"}</span>
          </div>

          <CustomModifiedSelectInput
            placeholder="Version"
            options={updatedOptions}
            selected={selectedVersion}
            setSelected={setSelectedVersion}
          />

          {data.incident.is_resolved ? (
            <div className="is-resolved button one-bg">
              <CheckmarkCircle02Icon />
              <span>Incident is closed</span>
            </div>
          ) : (
            <div
              onClick={toggleActions}
              className={`actions ${showActions ? "show-actions" : ""}`}
            >
              <button type="button" className="primary-button">
                <span>Actions</span>
                <div className="icon">
                  {showActions ? (
                    <ArrowDown01Icon variant={"stroke"} />
                  ) : (
                    <ArrowRight01Icon variant={"stroke"} />
                  )}
                </div>
              </button>
              <div className="actions-popup">
                <>
                  {(permissions && permissions.includes("Super User")) ||
                  permissions.includes("Admin") ||
                  permissions.includes("Quality - Risk Manager") ? (
                    <div
                      onClick={toggleShowSendToDepartmentForm}
                      className="action"
                    >
                      <div className="icon">
                        <MailSend02Icon size={20} variant={"stroke"} />
                      </div>
                      <span>Send to department</span>
                    </div>
                  ) : (
                    " "
                  )}

                  {permissions &&
                  (permissions.includes("Super User") ||
                    permissions.includes("Admin") ||
                    (showClosedManager && permissions.includes("Manager"))) ? (
                    <div
                      onClick={toggleShowMarkResolvedPopup}
                      className="action"
                    >
                      <div className="icon">
                        <DocumentValidationIcon size={20} variant={"stroke"} />
                      </div>
                      <span>Mark as closed</span>
                    </div>
                  ) : (
                    " "
                  )}
                </>

                {(permissions.includes("Super User") ||
                  permissions.includes("Admin") ||
                  permissions.includes("Quality - Risk Manager") ||
                  permissions.includes("Manager") ||
                  canViewDraft) && (
                  <Link
                    to={"modify/"}
                    onClick={() => {
                      localStorage.setItem("canModifyDraft", true);
                    }}
                    className="action"
                  >
                    <div className="icon">
                      <PencilEdit02Icon size={20} variant={"stroke"} />
                    </div>
                    <span>Modify</span>
                  </Link>
                )}

                {permissions &&
                (permissions.includes("Admin") ||
                  permissions.includes("Quality - Risk Manager") ||
                  permissions.includes("Super Admin") ||
                  permissions.includes("Manager")) ? (
                  <div onClick={toggleShowReviewForm} className="action">
                    <div className="icon">
                      <CommentAdd01Icon size={20} variant={"stroke"} />
                    </div>
                    <span>Add a comment</span>
                  </div>
                ) : (
                  " "
                )}
                {/* {permissions &&
                (permissions.includes("Admin") ||
                  permissions.includes("Quality - Risk Manager") ||
                  permissions.includes("Super Admin")) ? (
                  <div onClick={toggleShowPreviewForm} className="action">
                    <div className="icon">
                      <FileExportIcon size={20} variant={"stroke"} />
                    </div>
                    <span>Export Report</span>
                  </div>
                ) : (
                  " "
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="incident-details-and-tabs"></div>
      {showMarkResolvedPopup ? (
        <MarkResolvedForm
          incidentId={incidentDetailsId}
          apiLink={apiLink}
          closeForm={toggleShowMarkResolvedPopup}
        />
      ) : (
        ""
      )}
      {showReviewForm ? (
        <div className="incident-popup">
          <div className="popup-content review-popup-content">
            <h3>Add a comment</h3>
            <p>
              Your comments will be visible for anyone who can access this
              incident.
            </p>
            <ReviewFrom
              incidentId={incidentDetailsId}
              toggleReviewForm={toggleShowReviewForm}
              incidentName={apiLink}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {showPreviewForm ? (
        <div className="incident-popup">
          {" "}
          <GenerateReport />{" "}
        </div>
      ) : (
        ""
      )}
      {showSendToDepartmentForm ? (
        <SendToDepartmentForm
          closeForm={toggleShowSendToDepartmentForm}
          incidentId={incidentDetailsId}
          apiLink={apiLink}
          sendTo={sendTo}
          incident={data.incident}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default IncidentDetailsHeader;
