import React, { useState, useEffect } from "react";
import api, { API_URL } from "../../../../../api";
import DashBoardContainer from "../../../dashboardContainer";
import toast from "react-hot-toast";
import ModifyPageLoader from "./loader";
import { useParams } from "react-router-dom";
import GeneralFieldsForm from "./generalFields";
import IncidentTypeForm from "./incidentType";
import OutcomeForm from "./outcomeForm";
import "../../../../../assets/css/pages/modifyIncident/modifyIncident.css";
import ModifyGeneralIncidentForm from "../../../../../components/incidents/forms/modiy/generalIncident";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";

import { FacilityBreadCrumbs } from "../../drugReactionIncident/modifyMedicalAdverseDrugReactionIncidentPage.jsx";
import NoResources from "../../../../../components/general/noResources.jsx";

const PageContent = () => {
  const [incidentData, setIncidentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { incidentId } = useParams();
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchIncidentData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(
          `${API_URL}/incidents/general/${incidentId}/`
        );
        if (response.status === 200) {
          setIncidentData(response.data.incident);
          console.log("Incident data: ", response.data.incident);
          setIsLoading(false);
        }
      } catch (error) {
        // setIsLoading(false);
        console.log("new error", error);

        if (error.response.status && error.response.status === 403) {
          window.customToast.error("You are not allowed to view this incident");
        } else if (error.response.status === 404) {
          setIsError(true);
        } else {
          window.customToast.error("There was an error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchIncidentData();
  }, [incidentId]);

  return isLoading ? (
    <ModifyPageLoader />
  ) : incidentData && !isError ? (
    <ModifyGeneralIncidentForm data={incidentData} />
  ) : (
    <NoResources />
  );
};

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/general/"}>General Incidents List</Link>{" "}
      <ArrowRight01Icon />
      <Link to={`/incident/general/${incidentId}/`}>#{incidentId}</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> Modify</Link>
    </div>
  );
};

const ModifyGeneralIncident = ({ incidentId }) => {
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <DashBoardContainer
      content={<PageContent />}
      breadCrumbs={
        changeBreadCrumbs ? (
          <FacilityBreadCrumbs facilityIncidentLink={"general"} />
        ) : (
          <BreadCrumbs />
        )
      }
    />
  );
};

export default ModifyGeneralIncident;
