import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardContainer from "../dashboardContainer";
import IncidentDetailsHeader from "./incidentDetails/incidentDetailsHeader";
import IncidentDetails from "./incidentDetails/incidentDetails";
import IncidentTabs from "./incidentDetails/incidentDetailsTabs";
import api, { API_URL } from "../../../api";
import "../../../assets/css/pages/incidents/general/generalIncidentDetailsPage.css";
import MedicationDetailsContentTab from "./medicationIncident/medication";
import MedicationGeneralInfo from "./medicationIncident/medicationGeneralInfo";
import MedicationOtherInformation from "./medicationIncident/medicationOtherInformation";
import EmployeeDetails from "./employeeincident/employeeSimpleDetails";
import EmployeeDetailsContentTab from "./employeeincident/employee";
import EmployeeGeneralInfo from "./employeeincident/employeeGeneralInfo";
import EmployeeOtherInformation from "./employeeincident/employeeOtherInformation";
import IncidentsTab from "../../../components/incidents/employeeTab";
import EmployeeDocumentHistory from "./employeeincident/employeeDocumentHistory";
import EmployeeReviews from "./employeeincident/employeeReviews";
import EmployeeInvestigationInfo from "./employeeincident/employeeInvestigationInfo";
import toast from "react-hot-toast";
import FilesList from "../../../components/documents/filesList";
import { ArrowRight01Icon } from "hugeicons-react";
import { Link } from "react-router-dom";
import { FacilityDetailsBreadCrumbs } from "./DrugReactionDetails";
import NoResources from "../../../components/general/noResources";

const EmployeeDetailsContent = () => {
  // const { medicationId } = useParams();

  const { incidentId } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [incidentStatus, setIncidentStatus] = useState({});
  const [investigationInfo, setInvestigationInfo] = useState({});
  const [latestIncidentDetails, setLatestIncidentDetails] = useState({});
  const [useOriginalVersion, setUseOriginalVersion] = useState(true);
  const [currentIncidentData, setCurrentIncidentData] = useState({});

  const fetchIncidentDetails = async () => {
    setIsFetching(true);
    try {
      let response;

      // Fetch the original version of the incident
      if (useOriginalVersion) {
        response = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/`
        );
        setInvestigationInfo(response.data.investigation);
        setIncidentDetails(response.data); // Store the original data
        setCurrentIncidentData(response.data); // Set current data for UI
        console.log(response.data);
      } else {
        // Fetch the latest modified version of the incident
        const res = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/`
        );
        const latestIncident = res.data.modifications.versions.find((mod) => {
          return mod.latest === true;
        });

        if (latestIncident) {
          response = await api.get(
            `${API_URL}/incidents/employee_incident/${incidentId}/versions/${latestIncident.id}/`
          );
          console.log(response.data);
          console.log(latestIncident);
        } else {
          response = res;
        }
        setInvestigationInfo(response.data.investigation);
        setLatestIncidentDetails(response.data); // Store the latest modified version
        setCurrentIncidentData(response.data); // Set current data for UI
      }

      setIsFetching(false); // Stop loading state
    } catch (error) {
      console.error("Error fetching incident details:", error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchIncidentDetails(); // Fetch incident data when version toggles or incidentId changes
  }, [incidentId, useOriginalVersion]);

  useEffect(() => {
    const getIncidentReviews = async () => {
      try {
        const response = await api.get(
          `${API_URL}/incidents/employee_incident/${incidentId}/reviews/`
        );
        if (response.status === 200) {
          localStorage.setItem("incidentReviewsCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch incident reviews");
          console.error(error);
        }
      }
    };
    getIncidentReviews();
  }, []);
  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const response = await api.get(
          `${API_URL}/activities/list/${incidentId}/`
        );
        if (response.status === 200) {
          localStorage.setItem("documentHistoryCount", response.data.length);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          window.customToast.error("Authentication error");
        } else {
          window.customToast.error("Failed to fetch document History");
          console.error(error);
        }
      }
    };
    getDocumentHistory();
  }, []);
  return (
    <div className="incident-details-page">
      {isFetching ? (
        <div className="fetching-data">Loading data</div>
      ) : incidentDetails && Object.keys(incidentDetails).length > 0 ? (
        <div className="incident-details">
          {incidentDetails.modifications ? (
            <IncidentDetailsHeader
              data={
                useOriginalVersion ? incidentDetails : latestIncidentDetails
              }
              incidentDetailsId={incidentId}
              apiLink={"employee_incident"}
              sendTo={"send-to-department"}
              managerAccess={false}
              useOriginalVersion={useOriginalVersion}
              setCurrentIncidentData={setCurrentIncidentData}
              showClosedManager={true}
            />
          ) : (
            ""
          )}

          <div className="details">
            <EmployeeDetails
              data={currentIncidentData}
              fullName={`${currentIncidentData.patient_info?.user?.last_name} ${currentIncidentData.patient_info?.user?.first_name}`}
              location={currentIncidentData.location}
              IncidentDate={currentIncidentData.date_of_injury_or_near_miss}
              incidentTime={currentIncidentData.time_of_injury_or_near_miss}
              status={currentIncidentData.status}
              incidentDetails={
                <EmployeeDetailsContentTab data={currentIncidentData} />
              }
            />
            <IncidentTabs
              data={currentIncidentData}
              statuses={incidentStatus}
              generalInformation={
                <EmployeeGeneralInfo
                  data={currentIncidentData}
                  incidentStatuses={incidentStatus}
                />
              }
              otherInformation={
                <EmployeeOtherInformation data={currentIncidentData} />
              }
              documentHistory={
                <EmployeeDocumentHistory incidentId={incidentId} />
              }
              reviews={<EmployeeReviews incidentId={incidentId} />}
              documents={<IncidentDocuments incidentId={incidentId} />}
              investigation={
                <EmployeeInvestigationInfo data={investigationInfo} />
              }
              showInvestigationTab={true}
            />
          </div>
        </div>
      ) : (
        <NoResources />
      )}
    </div>
  );
};

const IncidentDocuments = ({ incidentId, apiLink }) => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.get(
          `/incidents/employee_incident/${incidentId}/documents/`
        );
        if (response.status === 200) {
          setDocuments(response.data);
          console.log(response.data);
          localStorage.setItem("incidentDocumentCount", response.data.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDocuments();
  }, []);
  return <FilesList documents={documents} showDownload={true} />;
};

// export default EmployeeDetailsContent;

const BreadCrumbs = () => {
  const { incidentId } = useParams();
  return (
    <div className="breadcrumbs">
      <Link to={"/"}>Overview</Link> <ArrowRight01Icon />
      <Link to={"/incidents/"}>Incidents</Link> <ArrowRight01Icon />
      <Link to={"/incident/employee/"}>Staff Incident Report</Link>{" "}
      <ArrowRight01Icon />
      <Link className="current-page"> #{incidentId}</Link>
    </div>
  );
};

const EmployeeIncidentDetails = () => {
  const { incidentId } = useParams();
  const changeBreadCrumbs = localStorage.getItem("changeBreadCrumbs");
  return (
    <div>
      <DashBoardContainer
        content={<EmployeeDetailsContent />}
        breadCrumbs={
          changeBreadCrumbs ? (
            <FacilityDetailsBreadCrumbs incidentID={incidentId} />
          ) : (
            <BreadCrumbs />
          )
        }
      />
    </div>
  );
};

export default EmployeeIncidentDetails;
