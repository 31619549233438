import React, { useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import api, { API_URL, USER_ENV } from "../../api";

import "../../assets/css/pages/auth/login.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import EmailPasswordLoginForm from "../../components/auth/EmailPasswordLoginForm";
const SessionExpired = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [hasAccess, setHasAccess] = useState(null);
  const { instance } = useMsal();
  const [loginWitAndEmailPassword, setLoginWithEmailAndPassword] =
    useState(null);

  const toggleLoginWithEmailAndPassword = () => {
    setLoginWithEmailAndPassword(!loginWitAndEmailPassword);
  };
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginRequest = {
        scopes: ["openid", "profile", "email", "User.Read"],
        prompt: "select_account",
      };
      const loginResponse = await instance.loginPopup(loginRequest);
      const accessToken = loginResponse.accessToken;

      await fetchUserDetails(accessToken);
    } catch (error) {
      setIsLoading(false);
      window.customToast.error("Error during login");
    }
  };

  const fetchUserDetails = async (accessToken) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.post(`${API_URL}/accounts/token/verify/`, {
        access: accessToken,
      });
      console.log(response.data);
      if (response.status === 200) {
        const accessToken = response.data.access;
        localStorage.setItem("access", accessToken);
        setHasAccess(true);
        window.customToast.success("Login successful!");
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 403 || error.response.status === 401) {
        window.customToast.error("We could not authenticate you!");
      } else if (error.response) {
        if (error.response.status) {
          setHasAccess(false);
          window.customToast.error(
            error.response.data.message || "Authentication failed"
          );
        }
      } else {
        setHasAccess(false);
        window.customToast.error("Error connecting to the backend");
      }
    }
  };

  console.log("user env: ", USER_ENV);

  return (
    <div className="login-page">
      <img src={"/images/login/login-image.png"} alt="" className="login-img" />
      <div className="login-form">
        <div className="branding">
          <img src={"/images/branding/logo.png"} alt="" className="logo" />
        </div>
        <h1>Login into your account</h1>
        <p>
          Please enter your credentials to access cohesive quality control
          platform
        </p>
        {loginWitAndEmailPassword ? (
          <EmailPasswordLoginForm
            toggleLoginWithEmailAndPassword={toggleLoginWithEmailAndPassword}
            refresh={true}
          />
        ) : (
          <>
            <div className="entities">
              <div
                onClick={(e) => setSelectedEntity("pawhuska")}
                className={`entity ${
                  selectedEntity === "pawhuska" ? "selected" : ""
                }`}
              >
                <p>Pawhuska</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("prague")}
                className={`entity ${
                  selectedEntity === "prague" ? "selected" : ""
                }`}
              >
                <p>Prague</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("carnegie")}
                className={`entity ${
                  selectedEntity === "carnegie" ? "selected" : ""
                }`}
              >
                <p>Carnegie</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("mangum")}
                className={`entity ${
                  selectedEntity === "mangum" ? "selected" : ""
                }`}
              >
                <p>Mangum</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("seiling")}
                className={`entity ${
                  selectedEntity === "seiling" ? "selected" : ""
                }`}
              >
                <p>Seiling</p>
              </div>
              <div
                onClick={(e) => setSelectedEntity("firstMed")}
                className={`entity ${
                  selectedEntity === "firstMed" ? "selected" : ""
                }`}
              >
                <p>FirstMed</p>
              </div>
            </div>
            {/* <button
              disabled={isLoading}
              className="primary-button"
              onClick={handleLogin}
            >
              {isLoading ? (
                <i className="fa-solid fa-circle-notch loading-icon"></i>
              ) : (
                <span>Login with your company email</span>
              )}
            </button> */}
          </>
        )}

        {(USER_ENV && USER_ENV === "test") ||
        (USER_ENV && USER_ENV === "dev") ? (
          <>
            {!loginWitAndEmailPassword ? (
              <>
                {/* <p>Or</p> */}
                <button
                  className="button tertiary-button"
                  onClick={toggleLoginWithEmailAndPassword}
                >
                  Login with email and password
                </button>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SessionExpired;
